import React from "react";
const __pages_import_0__ = React.lazy(() => import("/src/pages/__layout.tsx"));
const __pages_import_1__ = React.lazy(() => import("/src/pages/__layout/$slug.tsx"));
const __pages_import_2__ = React.lazy(() => import("/src/pages/__layout/__authed.tsx"));
const __pages_import_3__ = React.lazy(() => import("/src/pages/__layout/__authed/$slug.edit.tsx"));
const __pages_import_4__ = React.lazy(() => import("/src/pages/__layout/__authed/authors.$user.edit.tsx"));
const __pages_import_5__ = React.lazy(() => import("/src/pages/__layout/__authed/new.tsx"));
const __pages_import_6__ = React.lazy(() => import("/src/pages/__layout/about.tsx"));
const __pages_import_7__ = React.lazy(() => import("/src/pages/__layout/index.tsx"));
const __pages_import_8__ = React.lazy(() => import("/src/pages/__layout/authors/$user.tsx"));
const __pages_import_9__ = React.lazy(() => import("/src/pages/__layout/authors/index.tsx"));

const routes = [{"caseSensitive":false,"element":React.createElement(__pages_import_0__),"children":[{"caseSensitive":false,"path":":slug","element":React.createElement(__pages_import_1__)},{"caseSensitive":false,"element":React.createElement(__pages_import_2__),"children":[{"caseSensitive":false,"path":":slug/edit","element":React.createElement(__pages_import_3__)},{"caseSensitive":false,"path":"authors/:user/edit","element":React.createElement(__pages_import_4__)},{"caseSensitive":false,"path":"new","element":React.createElement(__pages_import_5__)}]},{"caseSensitive":false,"path":"about","element":React.createElement(__pages_import_6__)},{"caseSensitive":false,"path":"","element":React.createElement(__pages_import_7__)},{"caseSensitive":false,"path":"authors","children":[{"caseSensitive":false,"path":":user","element":React.createElement(__pages_import_8__)},{"caseSensitive":false,"path":"","element":React.createElement(__pages_import_9__)}]}]}];

export default routes;